<template>
  <div id="main">
    <div class="login d-flex align-items-center">
      <div class="w-100">
        <h1 class="title text-center mb-4">
          <div>The Nikon Difference</div>
        </h1>
        <div class="wrap">
          <div v-if="!modalPrivacyShow">
            <div class="px-5 py-4 p-md-6">
              <h5 class="title text-center mb-4">
                <div>Log Into NikonDifference App CMS:</div>
              </h5>
              <!-- <section>
                <div class="bsk-container" @click="signIn()">
                  <button class="bsk-btn bsk-btn-default main-form" style="background-color: white">
                    <object type="image/svg+xml" data="https://cdn.cdnlogo.com/logos/g/35/google-icon.svg" class="x-icon"></object>
                    <span class="x-text" style="color: black">Sign in with Google</span>
                  </button>
                </div>
              </section> -->
              <section class="section-margin">
                <div class="bsk-container" @click="signIn()">
                  <button class="bsk-btn bsk-btn-default main-form">
                    <span class="x-text">Log In</span>
                    <div style="cursor: pointer;" class="icon-container" @click="signIn()">
                      <img class="x-icon" src="@/assets/icons/google-icon.svg" />
                      <img class="x-icon" src="@/assets/icons/ms-pictogram.svg" />
                      <!-- <object type="image/svg+xml" data="https://cdn.cdnlogo.com/logos/g/35/google-icon.svg" class="x-icon" @click="signIn()"></object>
                      <object type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" class="x-icon" @click="signIn()"></object> -->
                    </div>
                  </button>
                </div>
              </section>
            </div>
          </div>
          <div v-else hide-footer>
            <div class="px-5 py-5 p-md-6">
              <div class="form-group mt-3">
                <p><b>Privacy Policy Notice</b></p>
              </div>
              <div class="form-group">
                <p style="margin-bottom: 0px">
                  Please review the privacy policy.
                </p>
                <p style="margin-bottom: 0px">
                  By accepting, you agree to the
                </p>
                <p style="margin-bottom: 0px">
                  term in our Privacy Policy.
                </p>
              </div>
              <div>
                <a
                  :href="privacyStatementUrl"
                  target="_blank"
                  style="color: black; text-decoration: underline"
                >
                  Read the full statement here.
                </a>
              </div>
              <div style="padding-top: 50px">
                <button
                  class="decline-privacy xs-mt1 btn btn-secondary"
                  style="width: 100px"
                  @click="hideModal"
                >
                  Decline
                </button>
                <button
                  type="button"
                  class="btn btn-primary rounded submit px-3 agree-privacy"
                  block
                  @click="goDashboard"
                >
                  I Agree
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentService from "@/service/DocumentService";
import { PublicClientApplication } from "@azure/msal-browser";
import Vue from 'vue';
import User from "../../models/user";
import AccessService from "../../service/AccessService";
import CountryPrivacyService from "../../service/CountryPrivacyService";
import CountryService from "../../service/CountryService";
import LanguageService from "../../service/LanguageService";
import LocalStoreService from "../../service/LocalStoreService";

export default {
  name: "Login",
  data() {
    return {
      user: new User(""),
      message: "",
      showPassword: false,
      modalPrivacyShow: false,
      loggedUser: null,
      privacyStatementUrl: "#",
      withOtp: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    disabledLogin() {
      return !(this.user.username && this.user.password);
    },
  },
  created() {
    this.$msalInstance = new PublicClientApplication(
        this.$store.state.azure.msalConfig,
    )
    const withOtp = this.$route.query.otp;
    if (withOtp) {
      this.withOtp = true;
    }
    Vue.$cookies.remove('token');
  },
  methods: {
    tooglePassword() {
      this.showPassword = !this.showPassword;
    },
    hideModal() {
      this.modalPrivacyShow = false;
    },
    confirmPrivacy() {
      const user = this.loggedUser;
      this.hideModal();
      if (user.token) {
        const hostname = window.location.hostname;
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://raw.githubusercontent.com/wrangr/psl/master/dist/psl.min.js");

        let psl = require('psl')
        let parsed = psl.parse(hostname);

        LocalStoreService.setUser(user);
        Vue.$cookies.set('token', user.token, '300s', null, parsed.domain);
      }
      this.doRedirect();
    },
    goDashboard() {
      const user = this.loggedUser;
      // this.hideModal();
      if (user.token) {
        const hostname = window.location.hostname;
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://raw.githubusercontent.com/wrangr/psl/master/dist/psl.min.js");

        let psl = require('psl')
        let parsed = psl.parse(hostname);

        LocalStoreService.setUser(user);
        Vue.$cookies.set('token', user.token, '300s', null, parsed.domain);
      }
      window.location.href = DocumentService.getRedirectUrl(user);
    },
    signIn() {
      sessionStorage.removeItem('msal.interaction.status')
      this.$msalInstance
      .loginPopup({})
      .then((azure) => {
        Vue.$cookies.set('account', this.$msalInstance.getAccountByHomeId(azure.account.homeAccountId), '86400s');
        Vue.$cookies.set('token_azure', azure.accessToken, '86400s');
        this.$store.dispatch("auth/loginAzure", azure.accessToken).then(
            (user) => {
              if (!user) {
                return;
              }
              if(user && user.expiration) document.cookie = `expiration=${user.expiration}`;
              this.modalPrivacyShow = true;
              CountryPrivacyService.getPrivacyStatementUrl(user).then(
                  (response) => {
                    if (response?.data?.privacyUrl) {
                      this.privacyStatementUrl = response.data.privacyUrl;
                    }
                    this.loggedUser = user;
                    // Show privacy popup
                    this.modalPrivacyShow = true;
                  }
              );
            },
            (error) => {
              this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              setTimeout(() =>{
                this.$msalInstance.logoutRedirect()
                this.$store.dispatch("auth/logout");
                this.$store.commit("survey/remove");
              }, 1500);
            }
        );
      })
      .catch(error => {
        console.error(`error during authentication: ${error}`);
      });
    },
    onChangedPassword() {
      this.doRedirect();
    },
    doRedirect() {
      const user = this.loggedUser;
      CountryService.getAll().then((response) => {
        this.$store.commit(
          "common/countries",
          Object.fromEntries(
            response.data.map((item) => [item.code, item.name])
          )
        );
      });
      LanguageService.getAll().then((response) => {
        this.$store.commit(
          "common/languages",
          Object.fromEntries(
            response.data.map((item) => [item.code, item.name])
          )
        );
      });
      AccessService.getAll().then((response) => {
        this.$store.commit(
          "common/accesses",
          Object.fromEntries(
            response.data.map((item) => [item.code, item.name])
          )
        );
      });

      this.$store.commit("survey/remove");
      if (user.isGlobal) {
        this.$router.push("/");
      } else {
        this.$router.push("/survey");
      }
    },
    handleLogin() {
      const user = { ...this.user, otp: this.withOtp };
      this.$store.dispatch("auth/login", user).then(
        (user) => {
          if (!user) {
            return;
          }
          CountryPrivacyService.getPrivacyStatementUrl(user).then(
            (response) => {
              if (response?.data?.privacyUrl) {
                this.privacyStatementUrl = response.data.privacyUrl;
              }
              this.loggedUser = user;
              // Show privacy popup
              this.modalPrivacyShow = true;
            }
          );
        },
        (error) => {
          console.log(error);
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
<style scoped>
#main {
  background-color: #ffffff;
  color: black;
}
.login {
  margin: auto;
  width: 445px;
  height: 100vh;
}
.w-100 {
  width: 100%;
}
.title {
  width: 100%;
}
.wrap {
  border: 2px solid #e1e1e1;
  border-radius: 10px;
}
.main-form {
  border: 2px solid #e1e1e1;
  border-radius: 30px;
  width: 100%;
  height: 60px;
  color: white;
  background-color: black;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
}
.form-control {
  height: calc(2.25rem + 2px);
}
.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.forgot-password {
  color: black;
}
.forgot-password-container {
  margin-top: 5px;
}
.btn-login {
  text-align: right;
}
.btn[type="submit"] {
  height: 100%;
}
.agree-privacy {
  float: right;
  width: 100px;
}
.agree-privacy:hover {
  background-color: #093c8f !important;
  transition: background-color 0.15s ease 0s !important;
}
.decline-privacy {
  background-color: #6c757d;
  color: white;
  border-color: #6c757d;
}
.decline-privacy:hover {
  background-color: #5a6268;
  border-color: #545b62;
}
.decline-privacy,
.agree-privacy,
.btn-login {
  height: 38px !important;
}
section {
  margin-top: 40px;
}
section:last-of-type {
  margin-bottom: 40px;
}
.bsk-container {
  margin-top: 15px;
}
.icon-container{
  display: flex;
  align-items: center;
  gap: 15px;
}
.section-margin {
  margin-top: 20px;
}

.x-icon {
  height: 2em;
  width: 2em;
  position: relative;
}
.x-text {
  font-family: 'Montserrat', sans-serif;
  /* top: -5px;
  position: relative;
  margin-right: 20px; */
}
.x-alt {
  color: #777;
}
</style>